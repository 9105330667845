import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/UI/Layout"
import Navbar from "../components/UI/Navbar"
import Seo from "../components/Seo"
import GalleryNav from "../components/UI/GalleryNav"
import InspirationGallery from "../components/InspirationGallery"
import CallToAction from "../components/CallToAction"

const query = graphql`
  {
    contentfulOutdoorLivingGallery {
      images {
        gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        title
        id
      }
    }
  }
`

export default function InspirationGalleryPage() {
  const {
    contentfulOutdoorLivingGallery: { images: imageList },
  } = useStaticQuery(query)

  return (
    <Layout>
      <Navbar />
      <Seo
        title="Outdoor Living"
        description="Looking to transform your outdoor living space? Check out our favorite photos from our customers enjoying their custom built water features!"
      />
      <GalleryNav title="Outdoor Living"/>
      <InspirationGallery
        description="Imagine walking past your waterfall down a stone pathway... a bridge crossing your meandering stream. At the end of the path is a cozy little seating area nestled into your landscaping.

You're able to sit and enjoy the splashing sounds of your waterfall while you chat with friends or enjoy a glass of wine with your sweetheart. It's filled with the magic of water and the beauty of natural elements that have culminated into a paradise all your own."
        imageList={imageList}
      />
      <CallToAction end={true} />
    </Layout>
  )
}
