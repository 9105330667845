import React from "react"
// import React, { useEffect } from "react"
// import { Link } from "gatsby"

// import { makeStyles, useTheme } from "@material-ui/core/styles"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
// import Button from "@material-ui/core/Button"
// import ButtonGroup from "@material-ui/core/ButtonGroup"
import Divider from "@material-ui/core/Divider"
// import useMediaQuery from "@material-ui/core/useMediaQuery"
import { alpha } from "@material-ui/core/styles/colorManipulator"

const useStyles = makeStyles(theme => ({
  ctr: {
    paddingTop: "7.25rem",
    paddingBottom: "2.25em",
  },
  h1: {
    fontSize: "2.9375rem",
    fontWeight: 400,
    // color: theme.palette.common.muted,
    color: theme.palette.secondary.light,
    [theme.breakpoints.down('md')]: {
      paddingTop: '1rem',
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.25rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem",
    },
  },
  // activeClass: {
  //   backgroundColor: theme.palette.primary.main,
  //   color: theme.palette.common.muted,
  //   "&:hover": {
  //     color: theme.palette.primary.main,
  //   },
  // },
  hr: {
    // backgroundColor: theme.palette.secondary.main,
    // backgroundColor: theme.palette.background.paper,
    maxWidth: "25%",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "2.5rem",
    borderTopWidth: 2,
    borderBottomWidth: 2,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderStyle: "solid",
    borderColor: theme.palette.secondary.main,
    [theme.breakpoints.down('md')]: {
      marginBottom: '.25rem',
    },
  },
  // btn: {
  //   transition: "0.5s",
  //   fontFamily: "Montserrat, Helvetica Neue",
  //   fontWeight: 700,
  //   "&:hover": {
  //     backgroundColor: theme.palette.primary.main,
  //     color: theme.palette.common.muted,
  //     borderColor: alpha(theme.palette.common.muted, 0.3),
  //   },
  // },
}))

export default function GalleryNav({title="Inspiration Gallery"}) {
  // const theme = useTheme()
  const classes = useStyles()
  // const [page, setPage] = React.useState(null)
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))
  // const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))

  // useEffect(() => {
  //   switch (window.location.pathname) {
  //     case "/photos":
  //       setPage(0)
  //       break
  //     case "/photos/waterfalls":
  //       setPage(1)
  //       break
  //     case "/photos/hardscapes":
  //       setPage(2)
  //       break
  //     default:
  //       break
  //   }
  // }, [])

  return (
    <Grid container className={classes.ctr} justifyContent="center">
      <Grid item xs={12}>
        <Typography
          variant="h1"
          align="center"
          className={classes.h1}
          gutterBottom
        >
          {title}
          {/* Inspiration Gallery */}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.hr} />
      </Grid>
      {/* <Grid item xs={12}>
        <Grid container justifyContent="center">
          <ButtonGroup
            size={matchesXS ? "small" : matchesSM ? "medium" : "large"}
            color="primary"
            aria-label="large outlined primary button group"
          >
            <Button
              component={Link}
              to="/photos"
              className={`${classes.btn} ${page === 0 && classes.activeClass}`}
            >
              Ponds
            </Button>
            <Button
              component={Link}
              to="/photos/waterfalls"
              className={`${classes.btn} ${page === 1 && classes.activeClass}`}
            >
              Waterfalls
            </Button>
            <Button
              component={Link}
              to="/photos/hardscapes"
              className={`${classes.btn} ${page === 2 && classes.activeClass}`}
            >
              Hardscapes
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid> */}
    </Grid>
  )
}
